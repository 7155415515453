import React, { useMemo, forwardRef, useRef, useImperativeHandle, Context } from 'react'
import Link, { LinkProps } from 'next/link'
import classnames from 'classnames'
import {
  formatUrl,
  isExternalUrl,
  AmplitudeContext,
  useClickEventTracking,
  ContextType,
} from '@platform/utils'

import styles from './icon-button.module.scss'

export enum IconButtonVariant {
  Default = 'default',
  Primary = 'primary',
  Secondary = 'secondary',
  Ghost = 'ghost',
}

type IconButtonBaseProps = {
  label: string
  variant?: 'ghost' | 'default' | 'primary' | 'secondary' | 'ghost-on-brand' | 'highlight-alt'
  size?: 'large' | 'medium' | 'small'
  type?: 'button' | 'submit' | 'reset'
  isLoading?: boolean
  disabled?: boolean
  icon: React.ElementType
  href?: string
  isExternalHref?: boolean
  className?: string
  trackingIdentifier?: string
}

type ButtonAsButton = IconButtonBaseProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof IconButtonBaseProps>

type ButtonAsLink = IconButtonBaseProps & Omit<LinkProps, keyof IconButtonBaseProps>

type ButtonAsExternal = IconButtonBaseProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof IconButtonBaseProps>

export type IconButtonProps = ButtonAsButton | ButtonAsExternal | ButtonAsLink

export const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IconButtonProps>(
  (
    {
      className = '',
      variant = 'default',
      size = 'medium',
      href,
      isExternalHref = false,
      isLoading = false,
      disabled = false,
      icon,
      type = 'button',
      label,
      trackingIdentifier,
      ...rest
    },
    ref
  ) => {
    const classes = classnames(styles['button'], styles[variant], styles[size], className)

    const innerRef = useRef<HTMLAnchorElement | HTMLButtonElement | null>(null)
    useImperativeHandle(ref, () => innerRef.current as HTMLButtonElement | HTMLAnchorElement)

    useClickEventTracking(
      innerRef,
      AmplitudeContext as unknown as Context<ContextType>,
      href ? 'link_click' : 'button_click',
      {
        id: trackingIdentifier,
        label: label,
      }
    )

    const renderChildren = useMemo(() => {
      const Icon = icon as React.ElementType
      return (
        <>
          <span className={styles['hidden-label']}>{label}</span>
          <Icon className={styles['icon']} />
        </>
      )
    }, [icon, label])

    if (href) {
      const formattedUrl = isExternalHref ? href : formatUrl(href)
      if (isExternalHref || isExternalUrl(href)) {
        return (
          <a
            className={classes}
            href={formattedUrl}
            ref={innerRef as React.Ref<HTMLAnchorElement>}
            title={label}
            {...(rest as ButtonAsLink)}
          >
            {renderChildren}
          </a>
        )
      }
      return (
        <Link
          href={formattedUrl}
          className={classes}
          ref={innerRef as React.Ref<HTMLAnchorElement>}
          title={label}
          {...(rest as ButtonAsLink)}
        >
          {renderChildren}
        </Link>
      )
    }

    return (
      <button
        className={classes}
        type={type}
        ref={innerRef as React.Ref<HTMLButtonElement>}
        disabled={disabled || isLoading}
        title={label}
        {...(rest as ButtonAsButton)}
      >
        {renderChildren}
      </button>
    )
  }
)

export default IconButton
