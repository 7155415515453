import { toast as displayToast, Toaster, ToastBar } from 'react-hot-toast'

import IconButton from '../icon-button/icon-button'
import { XIcon } from '../../assets/icons/icons'

import styles from './toast.module.scss'

export type ToastProps = {
  title: string
  description?: string
  variant?: 'default' | 'error'
}

const TOAST_DURATION = 5000
const TOAST_GUTTER_DISTANCE = 16

export const toast = ({ title, description, variant = 'default' }: ToastProps) => {
  const toastMessage = (
    <div className={styles['message-container']}>
      <p className={styles['title-text']}>{title}</p>
      {description && <p className={styles['description-text']}>{description}</p>}
    </div>
  )
  variant === 'error' ? displayToast.error(toastMessage) : displayToast(toastMessage)
}

export const Toast = () => {
  const onCloseToast = (id: string) => () => displayToast.dismiss(id)

  return (
    <Toaster
      position="top-center"
      containerClassName={styles['container']}
      toastOptions={{
        className: styles['content'],
        duration: TOAST_DURATION,
        error: {
          className: styles['error-content'],
          duration: TOAST_DURATION,
        },
      }}
      gutter={TOAST_GUTTER_DISTANCE}
    >
      {toastProps => (
        <ToastBar toast={toastProps}>
          {({ message }) => (
            <div className={styles['inner-content']}>
              {message}
              <IconButton
                className={styles['icon-button']}
                icon={XIcon}
                label="Close toast"
                onClick={onCloseToast(toastProps.id)}
                variant="ghost"
              />
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}
