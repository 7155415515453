export const environment = {
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SENTRY_ENV: process.env.NEXT_PUBLIC_SENTRY_ENV,
  SENTRY_RELEASE: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  TALENT_URL: process.env.NEXT_PUBLIC_TALENT_URL,
  COMPANY_URL: process.env.NEXT_PUBLIC_COMPANY_URL,
  WEBSITE_URL: process.env.NEXT_PUBLIC_WEBSITE_URL,
  GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
  RD_STATION_SRC: process.env.NEXT_PUBLIC_RD_STATION_SRC,
  HOTJAR_ID: process.env.NEXT_PUBLIC_WEBSITE_HOTJAR_ID,
}
