import type { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  title: 'Olby // Engaging tech talent for trustworthy companies',
  canonical: 'https://talent.olby.com',
  description: 'Get connected with life-changing and long-term international job opportunities.',
  openGraph: {
    title: 'Olby | Work from anywhere for the best US Companies',
    description: 'Get connected with life-changing and long-term international job opportunities.',
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_COMPANY_URL}/thumbnail.png`,
        width: 640,
        height: 360,
        alt: 'Olby, Inc. Logo',
        type: 'image/png',
      },
    ],
    type: 'website',
    site_name: 'Olby, Inc.',
  },
  twitter: {
    handle: '@beolby',
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon.png',
      sizes: '16x16',
    },
    {
      rel: 'icon',
      href: '/favicon@2x.png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      href: '/favicon@3x.png',
      sizes: '48x48',
    },
    {
      rel: 'apple-touch-icon',
      href: '/webclip.png',
      sizes: '128x128',
    },
  ],
}

export const COMPANY_SEO: DefaultSeoProps = {
  title: 'Olby // Engaging tech talent for trustworthy companies',
  canonical: 'https://company.olby.com',
  description:
    'Find the right brazilian talents to be part of your core team. Then, retain and delight your global talent with our perks.',
  openGraph: {
    title: 'Olby | Company Portal',
    description:
      'Find the right brazilian talents to be part of your core team. Then, retain and delight your global talent with our perks.',
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_COMPANY_URL}/thumbnail.png`,
        width: 640,
        height: 360,
        alt: 'Olby, Inc. Logo',
        type: 'image/png',
      },
    ],
    type: 'website',
    site_name: 'Olby, Inc.',
  },
  twitter: {
    handle: '@beolby',
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon.png',
      sizes: '16x16',
    },
    {
      rel: 'icon',
      href: '/favicon@2x.png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      href: '/favicon@3x.png',
      sizes: '48x48',
    },
    {
      rel: 'apple-touch-icon',
      href: '/webclip.png',
      sizes: '128x128',
    },
  ],
}

// TODO: Update website SEO when olby information defined
export const WEBSITE_SEO: DefaultSeoProps = {
  title: 'Olby // Engaging tech talent for trustworthy companies',
  canonical: 'https://olby.com',
  description: 'Olby reduces intercultural barriers and promotes thriving work relationships.',
  openGraph: {
    title: 'Olby // Engaging tech talent for trustworthy companies',
    description: 'Olby reduces intercultural barriers and promotes thriving work relationships.',
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_WEBSITE_URL}/thumbnail.png`,
        width: 640,
        height: 360,
        alt: 'Olby, Inc. Logo',
        type: 'image/png',
      },
    ],
    type: 'website',
    site_name: 'Olby, Inc.',
  },
  twitter: {
    handle: '@beolby',
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon.png',
      sizes: '16x16',
    },
    {
      rel: 'icon',
      href: '/favicon@2x.png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      href: '/favicon@3x.png',
      sizes: '48x48',
    },
    {
      rel: 'apple-touch-icon',
      href: '/webclip.png',
      sizes: '128x128',
    },
  ],
}
