import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'

declare global {
  interface Window {
    // TODO: Fix typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any
  }
}

type gaTrackingIdType = string | undefined

interface GtagProps {
  gaTrackingId: gaTrackingIdType
}

type ExtraProps = Record<string, string | number | string[]>

interface GAEventType {
  action: string
  category: string
  label: string
  value?: number
  extraProps?: ExtraProps
}

export const setGAUserId = (userId: string) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('set', 'user_id', userId)
  }
}

export const trackGAEvent = ({ action, category, label, value, extraProps }: GAEventType) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
      extra_props: extraProps,
    })
  }
}

const pageview = (url: string, gaTrackingId: gaTrackingIdType) => {
  if (!gaTrackingId || typeof window === 'undefined') {
    return
  }

  window.gtag('config', gaTrackingId, {
    page_path: url,
  })
}

export const Gtag = ({ gaTrackingId }: GtagProps) => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url, gaTrackingId)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, gaTrackingId])

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
        strategy="afterInteractive"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gaTrackingId}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  )
}
