import type { AppProps } from 'next/app'
import {
  Hydrate,
  MutationCache,
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query'
import { NextSeo } from 'next-seo'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ReactElement, ReactNode, useState } from 'react'
import type { NextPage } from 'next'
import Head from 'next/head'
import { CookiesProvider } from 'react-cookie'
import { Toast, toast } from '@platform/ui-kit'
import { Gtag, WEBSITE_SEO } from '@platform/utils'
import '@styles/settings.scss'
import type { DehydratedState } from '@tanstack/react-query'
import Script from 'next/script'

import { environment } from '../config/environment'

type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  pageProps: { dehydratedState: DehydratedState }
}

const CustomApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? (page => page)
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
        queryCache: new QueryCache({
          onError: error => {
            const { message } = error as Error
            toast({ title: 'Oops! Something went wrong.', description: message, variant: 'error' })
          },
        }),
        mutationCache: new MutationCache({
          onError: error => {
            const { message } = error as Error
            toast({ title: 'Oops! Something went wrong.', description: message, variant: 'error' })
          },
        }),
      })
  )

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <CookiesProvider>
            <NextSeo {...WEBSITE_SEO} />
            <Head>
              <title>Olby // Engaging tech talent for trustworthy companies</title>
            </Head>
            {getLayout(<Component {...pageProps} />)}
            <ReactQueryDevtools initialIsOpen={false} />
            <Toast />
          </CookiesProvider>
        </Hydrate>
      </QueryClientProvider>
      {environment.GA_MEASUREMENT_ID && <Gtag gaTrackingId={environment.GA_MEASUREMENT_ID} />}
      {environment.RD_STATION_SRC && (
        <Script type="text/javascript" src={environment.RD_STATION_SRC} async={true} />
      )}
      {environment.HOTJAR_ID && (
        <Script
          id="hotjar"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${environment.HOTJAR_ID},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
      )}
    </>
  )
}

export default CustomApp
